<template>
  <div>
    <nh-dialog
      v-model="visible"
      :title="title"
      append-to-body
      :before-close="handleClose"
      custom-class="custom-dialog_70"
      :track="{
        name_zh: `配置管理/货品存放设置/弹窗-${disabled?'详情':'编辑'}`,
        trackName: $route.path
      }"
    >
      <nh-dialog-form
        ref="formBase"
        :model="formBase"
        :rules="rules"
        label-width="110px"
        label-position="left"
        :disabled="disabled"
      >
        <nh-dialog-form-item
          :span="8"
          label="货品编码:"
          prop="goodsCode"
        >
          <span>{{ formBase.goodsCode }}</span>
        </nh-dialog-form-item>
        <nh-dialog-form-item
          :span="8"
          label="货品名称:"
          prop="goodsName"
        >
          {{ formBase.goodsName }}
        </nh-dialog-form-item>
        <nh-dialog-form-item
          :span="8"
          label="货主:"
          prop="shipperName"
        >
          {{ formBase.shipperName }}
        </nh-dialog-form-item>
        <nh-dialog-form-item
          :span="8"
          label="日常补货阈值:"
          prop="replenishmentThreshold"
        >
          <el-input
            v-model="formBase.replenishmentThreshold"
            class="custom-input"
            placeholder="整单位数量"
            @input="(val) => formBase.replenishmentThreshold =
              Number(val.replace(/[^0-9]/ig,'')) || null"
          />
        </nh-dialog-form-item>
        <nh-dialog-form-item
          :span="8"
          label="拣选区最小存量:"
          prop="stockMinimum"
        >
          <el-input
            v-model="formBase.stockMinimum"
            class="custom-input"
            placeholder="整单位数量"
            @input="(val) => formBase.stockMinimum =
              Number(val.replace(/[^0-9]/ig,'')) || null"
          />
        </nh-dialog-form-item>
        <nh-dialog-form-item
          :span="8"
          label="拣选区最大存量:"
          prop="stockMaximum"
        >
          <el-input
            v-model="formBase.stockMaximum"
            class="custom-input"
            placeholder="整单位数量"
            @input="(val) => formBase.stockMaximum =
              Number(val.replace(/[^0-9]/ig,'')) || null"
          />
        </nh-dialog-form-item>
      </nh-dialog-form>
      <el-tabs v-model="tab" type="border-card">
        <el-tab-pane label="指定拣选位" :name="tabs.pickingLocationInfos">
          <nh-dialog-form
            ref="formTrucks"
            :model="formTrucks"
            :rules="rules"
            label-width="110px"
            label-position="left"
            :disabled="disabled"
          >
            <nh-dialog-form-item
              class="goods_details margin-bottom0"
              label="是否指定拣选位"
              prop="appointPickingLocation"
            >
              <el-radio-group v-model="formTrucks.appointPickingLocation">
                <el-radio
                  v-for="item in WHETHER"
                  :key="item.value"
                  :label="item.value"
                  @change="changeAppointPickingLocation"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </nh-dialog-form-item>
            <nh-dialog-form-item
              v-if="formTrucks.appointPickingLocation !== false"
              class="goods_details margin-bottom0"
              :span="24"
              label=""
              label-width="0"
              prop="pickingLocationInfos"
            >
              <div class="designated-title">
                <p>已指定的拣选位</p>
                <nh-button @click="handleSelectStorage">
                  选择库位
                </nh-button>
              </div>
            </nh-dialog-form-item>
          </nh-dialog-form>
          <PlTable
            v-if="formTrucks.appointPickingLocation !== false"
            :data="formTrucks.pickingLocationInfos"
            :columns="TABLECOLUMN(disabled)"
          >
            <template #action="{row}">
              <nh-button type="text" @click="handleRemovePicking(row)">
                移除
              </nh-button>
            </template>
          </PlTable>
        </el-tab-pane>
        <el-tab-pane label="指定备货位" :name="tabs.stockUpLocationInfos">
          <nh-dialog-form
            ref="formStock"
            :model="formStock"
            :rules="rules"
            label-width="110px"
            label-position="left"
            :disabled="disabled"
          >
            <nh-dialog-form-item
              class="goods_details margin-bottom0"
              label="是否指定备货位"
              prop="appointStockUpLocation"
            >
              <el-radio-group v-model="formStock.appointStockUpLocation">
                <el-radio
                  v-for="item in WHETHER"
                  :key="item.value"
                  :label="item.value"
                  @change="changeAppointStockUpLocation"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </nh-dialog-form-item>
            <nh-dialog-form-item
              v-if="formStock.appointStockUpLocation !== false"
              class="goods_details margin-bottom0"
              :span="24"
              label=""
              label-width="0"
              prop="stockUpLocationInfos"
            >
              <div class="designated-title">
                <p>已指定的备货位</p>
                <nh-button @click="handleSelectStorage">
                  选择库位
                </nh-button>
              </div>
            </nh-dialog-form-item>
          </nh-dialog-form>
          <PlTable
            v-if="formStock.appointStockUpLocation !== false"
            :data="formStock.stockUpLocationInfos"
            :columns="TABLECOLUMN(disabled)"
          >
            <template #action="{row}">
              <nh-button type="text" @click="handleRemoveStock(row)">
                移除
              </nh-button>
            </template>
          </PlTable>
        </el-tab-pane>
      </el-tabs>
      <template #footer>
        <div class="dialog-footer">
          <div class="btn">
            <nh-button
              v-if="!disabled"
              type="primary"
              :loading="loading.handleSubmit"
              @click="handleSubmit"
            >
              保存
            </nh-button>
            <nh-button @click="handleClose">
              取消
            </nh-button>
          </div>
        </div>
      </template>
    </nh-dialog>
    <select-storage ref="selectStorage" @addStorage="addStorage" />
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import SelectStorage from './SelectStorage.vue';
import { WHETHER } from '../constant';
import TABLECOLUMN, { tabs } from './constant';
import { goodsDepositUpdate, ruleGoodsDepositGet } from '../api';

export default {
  name: 'WarehouseDetails',
  components: { SelectStorage },
  filters: {},
  mixins: [loadingMixin],
  props: {
    attributeCodes: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onSuccess'],
  data() {
    const rulesMax = (rule, value, callback) => {
      if (!!value && !!this.formBase.stockMinimum) {
        if (Number(this.formBase.stockMinimum) > value) {
          callback(new Error('应大于最小值!'));
        }
      }
      callback();
    };
    return {
      TABLECOLUMN,
      WHETHER,
      title: '编辑',
      visible: false,
      disabled: true,
      tabs,
      tab: tabs.pickingLocationInfos,
      formBase: {
        replenishmentThreshold: null,
        stockMinimum: null,
        stockMaximum: null,
      },
      formTrucks: {
        appointPickingLocation: null,
        pickingLocationInfos: [],
      },
      formStock: {
        appointStockUpLocation: null,
        stockUpLocationInfos: [],
      },
      rules: {
        stockMaximum: [{ validator: rulesMax, trigger: 'blur' }],
        appointPickingLocation: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        pickingLocationInfos: [
          {
            type: 'array', required: true, message: '请选择', trigger: 'change',
          },
        ],
        appointStockUpLocation: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        stockUpLocationInfos: [
          {
            type: 'array', required: true, message: '请选择', trigger: 'change',
          },
        ],
      },
      loading: {
        handleSubmit: false,
      },
    };
  },
  methods: {
    async onOpen({ goodsId }, DETAIL) {
      this.disabled = !!DETAIL;
      this.title = this.disabled ? '详情' : '编辑';
      this.visible = true;
      const {
        appointPickingLocation, appointStockUpLocation,
        pickingLocationInfos = [],
        stockUpLocationInfos = [], ...base
      } = await ruleGoodsDepositGet({ goodsId });
      this.formBase = base;
      this.formTrucks = {
        appointPickingLocation: !!appointPickingLocation,
        pickingLocationInfos,
      };
      this.formStock = {
        appointStockUpLocation: !!appointStockUpLocation,
        stockUpLocationInfos,
      };
    },
    changeAppointPickingLocation(val) {
      if (!val) {
        this.formTrucks.pickingLocationInfos = [];
      }
    },
    changeAppointStockUpLocation(val) {
      if (!val) {
        this.formStock.stockUpLocationInfos = [];
      }
    },
    handleSelectStorage() {
      let ids = [];
      if (this.tab === tabs.pickingLocationInfos) {
        ids = this.formTrucks.pickingLocationInfos.map((item) => item.storehouseCode);
      } else {
        ids = this.formStock.stockUpLocationInfos.map((item) => item.storehouseCode);
      }
      this.$refs.selectStorage.onOpen(ids, this.tab);
    },
    addStorage(storage = []) {
      if (this.tab === tabs.pickingLocationInfos) {
        this.formTrucks.pickingLocationInfos.push(...storage);
      } else {
        this.formStock.stockUpLocationInfos.push(...storage);
      }
    },
    handleRemovePicking(row) {
      this.formTrucks.pickingLocationInfos = this.formTrucks.pickingLocationInfos.filter(
        (item) => item.storehouseCode !== row.storehouseCode,
      );
    },
    handleRemoveStock(row) {
      this.formStock.stockUpLocationInfos = this.formStock.stockUpLocationInfos.filter(
        (item) => item.storehouseCode !== row.storehouseCode,
      );
    },
    async handleRuleForm() {
      const formBase = this.$refs.formBase.validate();
      const formTrucks = this.$refs.formTrucks.validate();
      const formStock = this.$refs.formStock.validate();
      return Promise.all([formBase, formTrucks, formStock]).then(() => true).catch(() => false);
    },
    async handleSubmit() {
      const rule = await this.handleRuleForm();
      const appointedPickingLocations = this.formTrucks.pickingLocationInfos.map(
        (item) => item.id,
      );
      const appointedStockUpLocations = this.formStock.stockUpLocationInfos.map(
        (item) => item.id,
      );
      if (rule) {
        await goodsDepositUpdate(
          {
            ...this.formBase,
            ...this.formTrucks,
            ...this.formStock,
            appointedPickingLocations,
            appointedStockUpLocations,
          },
        );
        this.$emit('onSuccess');
        this.visible = false;
      }
    },
    handleClose() {
      this.tab = tabs.pickingLocationInfos;
      this.$refs.formBase.resetFields();
      this.$refs.formTrucks.resetFields();
      this.$refs.formStock.resetFields();
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-input.el-input {
  width: 100%;
}

.designated-title {
  display: flex;
  justify-content: space-between;
}

.footer,
.from-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
}

.from-footer {
  justify-content: flex-end;
}

.width-100 {
  width: 100%;
}
</style>
<style lang="scss">
.goods_details.margin-bottom0 {
  margin-bottom: 8px;
}
</style>
