import { FormType } from '@/constant/form';

export default [
  {
    label: '库区',
    prop: 'warehouseAreaId',
    colAttrs: {
      span: 6,
    },
  },
  {
    label: '逻辑区',
    prop: 'abstractZoneId',
    colAttrs: {
      span: 6,
    },
  },
  {
    label: '巷道',
    prop: 'warehouseRow',
    colAttrs: {
      span: 6,
    },
    component: FormType.INPUT,
  },
  {
    label: '库位编码',
    prop: 'storageLocationCode',
    colAttrs: {
      span: 6,
    },
    component: FormType.INPUT,
  },
];
