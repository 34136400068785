<!--
 * @Author: your name
 * @Date: 2021-10-11 15:15:46
 * @LastEditTime: 2021-10-11 15:15:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fe-wms-v2-admin\src\views\config-management\goods-storage-setup\index.vue
-->
<template>
  <pl-block>
    <PlForm
      v-model="formData"
      mode="search"
      :fields="serchFields"
      @submit="handleSearch"
      @reset="handleSearch"
    >
      <template #shipperId>
        <nh-shipper-selector
          v-model:shipperId="formData.shipperId"
        />
      </template>
      <template #goodsLevel>
        <el-cascader
          v-model="formData.goodsLevel"
          :options="cascader"
          clearable
        />
      </template>
    </PlForm>
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loadingTable"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
    >
      <template #tableHeaderRight>
        <nh-button
          :track="{
            trackName: '配置管理/货品存放设置/[导出]'
          }"
          @click="handleExport"
        >
          导出
        </nh-button>
        <nh-button
          :track="{
            trackName: '配置管理/货品存放设置/[导入存放限制]'
          }"
          @click="handleInfoImport"
        >
          导入存放限制
        </nh-button>
      </template>
      <template #goodsCode="{row}">
        <nh-link
          :to="{
            path:'',
            query:{id: row.id}
          }"
          :text="row.goodsCode"
          @click="handleDetail(row)"
        />
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="handleEdit(row)">
          编辑
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <goods-details ref="details" @onSuccess="handleSuccess" />
  <nh-data-import
    ref="DataImport"
    :err-msg-list="errMsgList"
    :is-upload="isUpload"
    :track="{
      name_zh: `配置管理/货品存放设置/弹窗-导入`,
      trackName: $route.path
    }"
    @upload="warehouseGoodsImport"
    @downLoadTemplate="handleDownTemplate"
    @submitSuccess="handleGoodsImportSubmitSuccess"
    @cancel="handleGoodsImportCancel"
    @clearAll="handleGoodsImportClearAll"
  />
</template>
<script>
import loadingMixin from '@thales/loading';
import GoodsDetails from './components/GoodsDetails.vue';
import {
  TABLECOLUMN, serchFields,
  WAREHOUSE_STATUS,
} from './fileds';
import { DETAIL } from './constant';
import {
  goodsDepositPage, getGoodsLevelTree, goodsDepositExport,
  downLoadTemplate, importCheck, goodsDepositImport,
} from './api';

export default {
  name: 'SchedulingPlan',
  components: { GoodsDetails },
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      cascader: [],
      tableData: [],
      formData: {
        status: WAREHOUSE_STATUS[0].value,
      },
      loading: {
        getTableData: false,
        schedulePlanCopy: false,
      },
      imputData: [],
      isUpload: false,
      errMsgList: [],
    };
  },
  computed: {
    loadingTable() {
      return this.loading.getTableData || this.loading.schedulePlanCopy;
    },
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },

  created() {
    this.getLevelTree();
    this.getTableData();
  },
  methods: {
    readNodes(nodes = []) {
      const arr = [];
      nodes.forEach((item, index) => {
        arr.push({ label: item.name, value: item.name });
        if (item.children && item.children.length > 0) {
          arr[index].children = this.readNodes(item.children);
        }
      });
      return arr;
    },
    async getLevelTree() {
      const resp = await getGoodsLevelTree({});
      this.cascader = this.readNodes(resp);
    },
    handleSearch(data) {
      const [beginWorkTime, endWorkTime] = data.timeRange || [];
      const [goodsLevel1, goodsLevel2, goodsLevel3] = data.goodsLevel || [];
      this.formData = {
        ...data, beginWorkTime, endWorkTime, goodsLevel1, goodsLevel2, goodsLevel3,
      };
      this.pagination.page = 1;
      this.getTableData();
    },
    handleSuccess() {
      this.getLevelTree();
      this.getTableData();
    },
    async getTableData() {
      const { records = [], total = 0 } = await goodsDepositPage(this.formData, this.pagination);
      this.tableData = records;
      this.pagination.total = total;
    },
    handleInfoImport() {
      this.$refs.DataImport.showDialog();
    },
    handleEdit(row) {
      this.$refs.details.onOpen(row);
    },
    handleDetail(row) {
      this.$refs.details.onOpen(row, DETAIL);
    },
    async warehouseGoodsImport(fd) {
      const { errorMsg = [], data = [] } = await importCheck(fd);
      this.isUpload = true;
      this.imputData = data;
      this.errMsgList = [...this.errMsgList, ...errorMsg.map((item) => ({ errorMsg: item }))];
    },
    async handleDownTemplate() {
      await downLoadTemplate({ templateId: 'rule_goods_deposit_template_id' });
    },
    async handleGoodsImportSubmitSuccess() {
      if (this.errMsgList && this.errMsgList.length <= 0) {
        await goodsDepositImport({ data: this.imputData });
        this.$message({
          message: '保存成功',
          type: 'success',
        });
        this.handleGoodsImportClearAll();
        this.$refs.DataImport.handleClose();
      } else {
        this.$message({
          message: '请根据错误信息修改后再上传',
          type: 'warning',
        });
      }
    },
    handleGoodsImportClearAll() {
      this.isUpload = false;
      this.errMsgList = [];
    },
    handleGoodsImportCancel() {
      this.handleGoodsImportClearAll();
      this.$refs.DataImport.handleClose();
    },
    async handleExport() {
      goodsDepositExport(this.formData, { fileName: '货品存放限制', extension: 'xlsx' });
    },
  },
};
</script>
<style lang="scss" scoped>
.action {
  padding: 15px 0;
}

.cursor_col {
  cursor: pointer;
  color: #06b880;
}

.text-btn:not(:first-of-type) {
  padding-left: 8px;
}
</style>
