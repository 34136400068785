import { TemperatureLayerType } from '@/constant/temperatureLayer';
import { formatKeyValueArray } from '@/utils/base';
import { FormType } from '@/constant/form';
import { WHETHER, WHETHER_ENUM } from './constant';

export const WAREHOUSE_STATUS = [
  {
    label: '启用',
    value: 'ENABLED',
  }, {
    label: '禁用',
    value: 'DISABLED',
  },
];
export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '货品编码',
  prop: 'goodsCode',
  minWidth: 180,
}, {
  label: '货主货品编码',
  prop: 'shipperGoodsCode',
  minWidth: 150,
}, {
  label: '货品名称',
  prop: 'goodsName',
  minWidth: 120,
}, {
  label: '货品条码',
  prop: 'internationalCode',
  minWidth: 120,
}, {
  label: '货主',
  prop: 'shipperName',
  minWidth: 120,
}, {
  label: '货品分类',
  prop: 'goodsLevel',
  minWidth: 120,
  formatter: (row) => `${row.goodsLevel1 ? `${row.goodsLevel1};` : ''}${row.goodsLevel2 ? `${row.goodsLevel2};` : ''}${row.goodsLevel3 || ''}`,
}, {
  label: '温层',
  prop: 'temperatureLayer',
  formatter: ({ temperatureLayer }) => TemperatureLayerType[temperatureLayer],
}, {
  label: '已配置库位',
  showOverflowTooltip: true,
  formatter: ({ pickingLocationInfos }) => {
    const storehouseCodes = pickingLocationInfos.map((item) => item.storehouseCode);
    return storehouseCodes.join(',');
  },
  minWidth: 200,
}, {
  label: '单板数量',
  prop: 'veneerWeight',
  minWidth: 120,
}, {
  label: '规格',
  prop: 'goodsSpecifications',
  minWidth: 120,
}, {
  label: '单位',
  prop: 'bigUnit',
}, {
  label: '日常补货阈值',
  prop: 'replenishmentThreshold',
  minWidth: 120,
}, {
  label: '最小存量',
  prop: 'stockMinimum',
  minWidth: 120,
}, {
  label: '最大存量',
  prop: 'stockMaximum',
  minWidth: 120,
}, {
  label: '是否指定拣选位',
  prop: 'appointPickingLocation',
  minWidth: 120,
  formatter: ({ appointPickingLocation }) => WHETHER_ENUM[appointPickingLocation],
}, {
  label: '是否指定备货位',
  prop: 'appointStockUpLocation',
  minWidth: 120,
  formatter: ({ appointStockUpLocation }) => WHETHER_ENUM[appointStockUpLocation],
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 80,
}];
export const serchFields = [
  {
    label: '货主',
    prop: 'shipperId',
  },
  {
    label: '货品编码',
    prop: 'goodsCode',
    component: FormType.INPUT,
  },
  {
    label: '货品名称',
    prop: 'goodsName',
    component: FormType.INPUT,
  },
  {
    label: '状态',
    prop: 'status',
    component: FormType.SELECT,
    options: WAREHOUSE_STATUS,
  },
  {
    label: '货品分类',
    prop: 'goodsLevel',
  },
  {
    label: '温层',
    prop: 'temperatureLayer',
    component: FormType.SELECT,
    options: formatKeyValueArray(TemperatureLayerType),
  },
  {
    label: '是否指定拣选位',
    prop: 'appointPickingLocation',
    component: FormType.SELECT,
    options: WHETHER,
  },
  {
    label: '是否指定备货位',
    prop: 'appointStockUpLocation',
    component: FormType.SELECT,
    options: WHETHER,
  },
];
