import { formatKeyValueObject } from '@/utils/base';

export default function TABLECOLUMN(isVIew) {
  const table = [{
    label: '库位',
    prop: 'storehouseCode',
    minWidth: 140,
  }, {
    label: '所属库区',
    prop: 'warehouseAreaName',
    minWidth: 200,
  }, {
    label: '巷道',
    prop: 'warehouseRow',
    minWidth: 120,
  }, {
    label: '列',
    prop: 'col',
    minWidth: 120,
  }, {
    label: '层',
    prop: 'layer',
    minWidth: 120,
  }];
  if (!isVIew) {
    return [...table, {
      label: '操作',
      prop: 'action',
      fixed: 'right',
      width: 80,
    }];
  }
  return table;
}

export const tabs = {
  pickingLocationInfos: 'pickingLocationInfos',
  stockUpLocationInfos: 'stockUpLocationInfos',
};
export function STORAGE_TABLECOLUMN(storeType) {
  return [
    {
      type: 'index',
      width: 50,
    },
    {
      type: 'selection',
      width: 50,
    },
    {
      label: '库位编号',
      prop: 'storageLocationCode',
      minWidth: 150,
    }, {
      label: '库区编号',
      prop: 'warehouseZoneCode',
      minWidth: 120,
    }, {
      label: '库区类型',
      prop: 'warehouseZoneTypeCode',
      minWidth: 100,
      formatter: ({ warehouseZoneTypeCode }) => formatKeyValueObject(
        storeType,
      )[warehouseZoneTypeCode],
    }, {
      label: '巷道',
      prop: 'warehouseRow',
      minWidth: 80,
    },
    {
      label: '列',
      prop: 'col',
      minWidth: 80,
    },
    {
      label: '层',
      prop: 'layer',
      minWidth: 80,
    },
    {
      label: '是否已绑定',
      prop: 'layer', // TODO
      formatter: ({ isUsed }) => `${isUsed ? '已' : '未'}绑定`,
    },
  ];
}
