import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 货品存放配置表 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16411
 */
export function goodsDepositPage(data, params = {
  page: 1,
  size: 10,
}) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_goods_deposit/page', data, {
    params,
  });
}
/**
 * @description: 货品分类
 * @api文档：https://yapi.ops.yunlizhi.cn/project/271/interface/api/9292
 */
export function getGoodsLevelTree(data) {
  return wmsPlusHttp.post('/goods_level/tree', data);
}

/**
 * @description: 库位管理 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18265
 */
export function searchStorehoue(data, params = {
  page: 1,
  size: 10,
}) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_goods_deposit/storehoue/page', data, {
    params,
  });
}
/**
 * @description: 库区信息表 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11164
 */

export function warehouseZonePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/zone/page', data, {
    params,
  });
}
/**
 * @description: 逻辑库区 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11248
 */

export function abstractZonePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/abstract_zone/page', data, {
    params,
  });
}
/**
 * @description: 货品存放配置表 根据ID获取
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16475
 */

export function ruleGoodsDepositGet(data) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_goods_deposit/get', data);
}
/**
 * @description: 货品存放配置表 修改
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16407
 */

export function goodsDepositUpdate(data) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_goods_deposit/update', data);
}
/**
 * @description: 货品规则导入检查
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16479
 */
export function importCheck(fd) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_goods_deposit/import/check', fd, {});
}
/**
 * @description: 货品规则配置导入
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16481
 */
export function goodsDepositImport(data) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_goods_deposit/import', data);
}
/**
 * @description: 货品规则配置导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16477
 */
export function goodsDepositExport(data, file) {
  return wmsPlusHttp.download('/warehouse_management_system/rule_goods_deposit/export', data, file);
}
/**
 * @description: 下载模板
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12508
 *
 */
export function downLoadTemplate(data) {
  return wmsPlusHttp.download('/warehouse_management_system/file/template/download', data, { fileName: '货品存放规则模板', extension: 'xlsx' });
}
