import { formatKeyValueObject } from '@/utils/base';

export const type = [];
export const DETAIL = true;
export const WHETHER = [{
  value: true,
  label: '是',
}, {
  value: false,
  label: '否',
}];
export const WHETHER_ENUM = formatKeyValueObject(WHETHER);
