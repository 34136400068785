<template>
  <div>
    <nh-dialog
      v-model="visible"
      :title="title"
      :before-close="handleClose"
      append-to-body
      custom-class="custom-dialog_60"
    >
      <PlForm
        v-model="form"
        mode="normal"
        :fields="selectFields"
        :collapse="false"
        submit-btn-text="搜 索"
        :form-props="{labelWidth:'80px'}"
        @submit="()=> {pagination.page = 1 ;getTableData()}"
        @reset="()=> {pagination.page = 1 ;getTableData()}"
      >
        <template #warehouseAreaId>
          <nh-remote-select
            v-model="form.warehouseAreaId"
            class="custom-input"
            :remote-method="handleWarehouseArea"
            :loading="loading.handleWarehouseArea"
          >
            <el-option
              v-for="item in warehouseAreaList"
              :key="item.id"
              :label="item.zoneName"
              :value="item.id"
            />
          </nh-remote-select>
        </template>
        <template #abstractZoneId>
          <nh-remote-select
            v-model="form.abstractZoneId"
            :remote-method="getAbstractZonePage"
            class="custom-input"
            :loading="loading.getAbstractZonePage"
          >
            <el-option
              v-for="item in abstractZone"
              :key="item.id"
              :label="item.abstractZoneName"
              :value="item.id"
            />
          </nh-remote-select>
        </template>
      </PlForm>
      <PlTable
        :pagination="pagination"
        :data="tableData"
        :columns="STORAGE_TABLECOLUMN(storeType)"
        @selection-change="handleSelect"
        @update:pagination="getTableData"
      >
        <template #selection>
          <el-table-column
            type="selection"
            :selectable="(row) =>!ids.includes(row.storageLocationCode)"
          />
        </template>
      </PlTable>
      <template #footer>
        <div class="dialog-footer">
          <div class="btn">
            <nh-button
              type="primary"
              :loading="loading.handleSave"
              @click="handleSave"
            >
              确认
            </nh-button>
            <nh-button @click="handleClose">
              取消
            </nh-button>
          </div>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import minxin from './mixin';
import { STORAGE_TABLECOLUMN } from './constant';
import { searchStorehoue, warehouseZonePage, abstractZonePage } from '../api';
import selectFields from './fileds';

export default {
  name: 'WarehouseDetails',
  components: {},
  filters: {},
  mixins: [loadingMixin, minxin],
  props: {
    attributeCodes: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['addStorage'],
  data() {
    return {
      STORAGE_TABLECOLUMN,
      title: '选择库位',
      visible: false,
      selectFields,
      warehouseAreaList: [],
      abstractZone: [],
      form: {
        warehouseAreaId: null,
        storageLocationCode: null,
        abstractZoneId: null,
        warehouseRow: null,
      },
      ids: [],
      tableData: [],
      loading: {},
    };
  },
  methods: {
    onOpen(ids = []) {
      this.visible = true;
      this.ids = ids;
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData(pagination) {
      if (pagination) {
        this.pagination = pagination;
      }
      const { records = [], total = 0 } = await searchStorehoue(this.form, this.pagination);
      this.tableData = records.map((item) => ({
        ...item,
        storehouseCode: item.storageLocationCode,
      }));
      this.pagination.total = total;
    },
    async handleWarehouseArea(query) {
      const { records = [] } = await warehouseZonePage({ page: 1, size: 100 }, { zoneName: query });
      this.warehouseAreaList = records;
    },
    async getAbstractZonePage(query) {
      const { records = [] } = await abstractZonePage(
        { page: 1, size: 100 }, { abstractZoneName: query },
      );
      this.abstractZone = records;
    },
    handleSelect(select) {
      this.select = select;
    },
    handleSave() {
      this.$emit('addStorage', this.select);
      this.handleClose();
    },
    handleClose() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-input.el-input {
  width: 100%;
  line-height: 32px;
}

.designated-title {
  display: flex;
  justify-content: space-between;
}

.footer,
.from-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
}

.from-footer {
  justify-content: flex-end;
}

.width-100 {
  width: 100%;
}
</style>
<style lang="scss">
.custom-input {
  .el-input {
    width: 100%;
  }
}
</style>
